import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    create,
    remove,
    presignFile,
} from 'Redux/modules/admin/customEvents/actions';

import {
    list as listOffers,
} from 'Redux/modules/admin/offers/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            update: dispatch(update),
            create: dispatch(create),
            remove: dispatch(remove),
            presignFile: dispatch(presignFile),
            listOffers: dispatch(listOffers),
        },
    }),
)(Component);