import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { ADMIN_CUSTOM_EVENTS } from 'Consts/routes';
import { ENDPOINT_ADMIN_FILE_PRESIGN } from 'Consts/api';
import { API_RESOURCE_OFFERS } from 'Consts/apiResources';

import { filterKeys, fromSelectObject } from 'Utils/object';
import { parseQueryToObject } from 'Utils/querystring';
import { toApiFormat as dateToApiFormat } from 'Utils/date';
import { getName } from 'Utils/offer';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class AdminCustomEventEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
            create: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
            presignFile: PropTypes.func.isRequired,
            listOffers: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };

    state = {
        formState: {},
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));
    }

    dataToFormState = data => {
        return {
            ...data,
            offer: data.offer
                ? { value: data.offer.id, label: getName(data.offer, true).label }
                : null,
            popUpTitle: data.popUp?.title,
            popUpContent: data.popUp?.content,
            popUpImage: data.popUp?.image,
        };
    }

    formStateToData = formState => {
        return {
            ...formState,
            titleBackgroundId: formState.titleBackground ? formState.titleBackground.id : undefined,
            activeFrom: formState.activeFrom ? dateToApiFormat(formState.activeFrom, 'datetime', true) : undefined,
            activeTo: formState.activeTo ? dateToApiFormat(formState.activeTo, 'datetime', true) : undefined,
            offerId: fromSelectObject(formState.offer),
            withPopUp: Boolean(formState.popUpTitle || formState.popUpContent || formState.popUpImage),
            popUpImageId: formState.popUpImage ? formState.popUpImage.id : undefined,
        };
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onUpdate = formState => {
        const { actions, data } = this.props;

        return actions.update({
            ...this.formStateToData(formState),
            id: data.id,
        }).catch(error => {
            Object.keys(error.payload.validationErrors).map(fieldName => {
                error.payload.validationErrors[fieldName].map(errorMessage => {
                    toast.error(`${errorMessage}`);
                });
            });

            throw error;
        });
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...this.formStateToData(formState),
        }).then(() => {
            history.push(ADMIN_CUSTOM_EVENTS.path);
        }).catch(error => {
            Object.keys(error.payload.validationErrors).map(fieldName => {
                error.payload.validationErrors[fieldName].map(errorMessage => {
                    toast.error(`${errorMessage}`);
                });
            });

            throw error;
        });
    }

    render() {
        const { location, history, data, actions } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="admin-custom-events-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    controls={[{
                        visible: Boolean(data),
                        title: 'Usuń wydarzenie',
                        subtitle: 'Wydarzenie niestandardowe zostanie usunięte',
                        buttonProps: {
                            onClick: () => actions.remove({ id: data && data.id }).then(() => {
                                return history.push(ADMIN_CUSTOM_EVENTS.path);
                            }),
                            children: 'Usuń',
                        },
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'title',
                            label: 'Tytuł',
                            required: true,
                        }, {
                            type: 'textarea',
                            name: 'content',
                            label: 'Treść',
                            required: true,
                        }, {
                            type: 'datePicker',
                            name: 'activeFrom',
                            label: 'Data startu aktywności',
                            inputProps: {
                                datePickerProps: {
                                    dateFormat: 'yyyy-MM-dd HH:mm:ss',
                                    showTimeSelect: true,
                                },
                                border: 'light-2', 
                            },
                            required: true,
                        }, {
                            type: 'datePicker',
                            name: 'activeTo',
                            label: 'Data końca aktywności',
                            inputProps: {
                                datePickerProps: {
                                    dateFormat: 'yyyy-MM-dd HH:mm:ss',
                                    showTimeSelect: true,
                                },
                                border: 'light-2', 
                            },
                            required: true,
                        }, {
                            type: 'select',
                            name: 'offer',
                            label: 'Oferta',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listOffers({
                                    search: query,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_OFFERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getName(element, true).label,
                                }),
                            },
                        }, {
                            type: 's3FileUpload',
                            name: 'titleBackground',
                            label: 'Tło',
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_ADMIN_FILE_PRESIGN
                                        .replace('{type}', 'customEvent'),
                                },
                            },
                        }, {
                            type: 'element',
                            key: 'popUp',
                            children: (
                                <>
                                    <p>Ustawienia pop up</p>
                                </>
                            )
                        }, {
                            type: 'input',
                            name: 'popUpTitle',
                            label: 'Tytuł',
                        }, {
                            type: 'textarea',
                            name: 'popUpContent',
                            label: 'Treść',
                        }, {
                            type: 's3FileUpload',
                            name: 'popUpImage',
                            label: 'Zdjęcie',
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_ADMIN_FILE_PRESIGN
                                        .replace('{type}', 'customEventPopUp'),
                                },
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}